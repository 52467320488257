<template>
  <vx-card title="Route Plan Import">
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Group</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          label="code"
          :searchable="true"
          :options="optionRouteGroup"
          :clearable="false"
          v-model="selectedRouteGroup"
        >
          <template slot="option" slot-scope="option">
            {{ option.code }}
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.code }}
          </template>
        </v-select>
        <multiselect
          class="selectExample"
          v-model="selectedRouteGroup"
          :options="optionRouteGroup"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="true"
          placeholder="Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div v-if="this.selectedRouteGroup">
      <div
        class="vx-row mb-6"
        style="width: 50%"
        v-if="this.selectedRouteGroup.code == 'Monthly'"
      >
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Month</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <datepicker
            name="month"
            format="MMM yyyy"
            :inline="false"
            v-model="month"
            minimum-view="month"
            placeholder="Select Month"
          ></datepicker>
        </div>
      </div>
    </div> -->
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-download"
          @click="handleDownloadTemplate()"
          >Template Download</vs-button
        >
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          label="name"
          :searchable="true"
          :options="optionTerritory"
          :clearable="false"
          v-model="selectedTerritory"
        >
          <template slot="option" slot-scope="option">
            {{ option.code }} {{ option.name }}
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.code }} {{ option.name }}
          </template>
        </v-select>

        <!-- <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableTerritory"
        /> -->
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Zone</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          label="name"
          :searchable="true"
          :options="optionZone"
          :clearable="false"
          v-model="selectedZone"
        >
          <template slot="option" slot-scope="option">
            {{ option.code }} {{ option.name }}
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.code }} {{ option.name }}
          </template>
        </v-select>
        <!-- <multiselect
          class="selectExample"
          v-model="selectedZone"
          :options="optionZone"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableTerritory"
        /> -->
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Option</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="options"
          :clearable="false"
          v-model="selectedOption"
        />
      </div>
    </div>
    <!-- <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.selectedOption != 'New'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Plan</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="optionRoutePlan"
          :clearable="false"
          v-model="selectedRoutePlan"
        />
      </div>
    </div> -->
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" />
      </div>
    </div> -->
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File (xls, xlsx)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          type="file"
          ref="fileInput"
          accept=".xlsx, .xls"
          @change="handleChangeFile"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button
          v-if="hasPermission('create')"
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-upload"
          @click="handleImport()"
          >Import</vs-button
        >
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Import">
            <data-table-log-import
              :baseUrl="this.baseUrl"
              :draw="draw"
              @logImportLine="handleLogImportLine"
            ></data-table-log-import>
          </vs-tab>
          <vs-tab label="Import Line">
            <data-table-log-import-line
              :baseUrl="this.baseUrl"
              :logImportID="this.logImportID"
            ></data-table-log-import-line>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import vSelect from "vue-select";
import DataTableLogImport from "./DataTableLogImport.vue";
import DataTableLogImportLine from "./DataTableLogImportLine.vue";
export default {
  components: {
    Datepicker,
    vSelect,
    DataTableLogImport,
    DataTableLogImportLine,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/route-plan",

      activeTab: 0,
      optionRouteGroup: [],
      selectedRouteGroup: null,
      month: null,

      optionTerritory: [],
      selectedTerritory: null,
      optionZone: [],
      selectedZone: null,
      description: "",

      options: ["New", "Add", "Replace"],
      selectedOption: "New",

      optionRoutePlan: [],
      selectedRoutePlan: null,
      file: null,
      draw: 0,
      logImportID: 0,
    };
  },
  methods: {
    setDefault() {
      this.activeTab = 0;
      this.selectedRouteGroup = this.optionRouteGroup[0];
      this.selectedTerritory = this.optionTerritory[0];
      this.selectedOption = "New";
      this.selectedRoutePlan = null;
      this.month = null;
      this.description = "";
      this.file = null;
      this.logImportID = 0;
      this.$refs.fileInput.value = null;
    },
    handleLogImportLine(id) {
      this.activeTab = 1;
      this.logImportID = id;
    },
    handleDownloadTemplate() {
      this.$vs.loading();
      var fileTitle =
        "ROUTE_PLAN_TEMPLATE_" + this.selectedRouteGroup.schedule_type;
      this.$http
        .get(this.baseUrl + "/import/template", {
          params: {
            schedule_type: this.selectedRouteGroup.schedule_type,
            month: this.month ? moment(this.month).format("YYYY-MM-DD") : null,
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    handleImport() {
      // if (this.selectedRouteGroup.schedule_type == "Monthly") {
      //   if (!this.month) {
      //     this.$vs.notify({
      //       color: "danger",
      //       title: "Error",
      //       text: "Month is required",
      //       position: "top-right",
      //       iconPack: "feather",
      //       icon: "icon-x-circle",
      //     });

      //     return false;
      //   }
      // }

      if (!this.selectedTerritory) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return false;
      }

      if (!this.selectedZone) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Zone is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return false;
      }

      // if (this.selectedOption != "New") {
      //   if (!this.selectedRoutePlan) {
      //     this.$vs.notify({
      //       color: "danger",
      //       title: "Error",
      //       text: "Route Plan is required",
      //       position: "top-right",
      //       iconPack: "feather",
      //       icon: "icon-x-circle",
      //     });
      //     return false;
      //   }
      // }

      if (!this.file) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let params = {
        // route_group_id: this.selectedRouteGroup.id,
        // schedule_type: this.selectedRouteGroup.schedule_type,
        // month: this.month ? moment(this.month).format("YYYY-MM-DD") : null,
        territory_id: this.selectedTerritory.id,
        zone_id: this.selectedZone.id,
        option: this.selectedOption,
        // route_plan_id: this.selectedRoutePlan
        //   ? this.selectedRoutePlan.id
        //   : null,
        // description: this.description,
      };

      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("data", JSON.stringify(params));

      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/import/create", formData)
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.$vs.loading.close();
            this.draw++;
            this.setDefault();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });

      console.log(params);
    },
    handleChangeFile(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      // console.log(this.file);
      if (this.file) {
        const reader = new FileReader();

        // reader.onload = (e) => {
        //   /* Parse data */
        //   const bstr = e.target.result;
        //   const wb = XLSX.read(bstr, { type: "binary" });
        //   /* Get first worksheet */
        //   const wsname = wb.SheetNames[0];
        //   const ws = wb.Sheets[wsname];
        //   /* Convert array of arrays */
        //   const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        // };

        reader.readAsBinaryString(this.file);
      }
    },
    getRouteGroup() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/route-group", {
          params: {
            order: "id",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionRouteGroup = resp.data.records;
              if (this.optionRouteGroup.length > 0) {
                this.selectedRouteGroup = this.optionRouteGroup[0];
              } else {
                this.optionRouteGroup = [];
                this.selectedRouteGroup = {};
              }
            } else {
              this.optionRouteGroup = [];
              this.selectedRouteGroup = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getRoutePlan() {
      if (this.selectedTerritory)
        if (this.selectedTerritory.id && this.selectedOption != "New") {
          this.$vs.loading();
          this.$http
            .get(this.baseUrl + "/route-plan", {
              params: {
                order: "code",
                sort: "desc",
                route_group_id: this.selectedRouteGroup.id,
                territory_id: this.selectedTerritory.id,
                zone_id: this.selectedZone.id,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  this.optionRoutePlan = resp.data.records;
                } else {
                  this.optionRoutePlan = [];
                }
              } else {
                this.optionRoutePlan = [];
              }

              this.$vs.loading.close();
            });
        } else {
          this.optionRoutePlan = [];
        }
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getZone() {
      if (this.selectedTerritory.code) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/zone", {
            params: {
              length: 0,
              order: "name",
              sort: "asc",
              territory_code: this.selectedTerritory.code,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                this.optionZone = resp.data.records;
                if (this.optionZone.length > 0) {
                  this.selectedZone = this.optionZone[0];
                } else {
                  this.optionZone = [];
                  this.selectedZone = {};
                }
              } else {
                this.optionZone = [];
                this.selectedZone = {};
              }
              this.$vs.loading.close();
            } else {
              this.optionZone = [];
              this.selectedZone = {};
              this.$vs.loading.close();
            }
          });
      } else {
        this.optionZone = [];
        this.selectedZone = {};
      }
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
  },
  mounted() {
    this.getRouteGroup();
    this.getTerritory();
    this.$store.dispatch("user/getPermissions", "master-route-plan");
  },
  watch: {
    selectedTerritory() {
      this.getZone();
      this.getRoutePlan();
    },
    selectedTZone() {
      this.getRoutePlan();
    },
    // selectedOption() {
    //   this.getRoutePlan();
    // },

    // selectedRouteGroup() {
    //   this.getRoutePlan();
    //   this.selectedRoutePlan = null;
    // },
  },
};
</script>
